'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

// This is a wrapper class that establishes context for an OTSubscriber
// instance.  This allows stream to be passed to the
// OTSubscriber without requiring each child element to pas through props.
// There should be a 1:1 ratio between OTSubscriberContext's and OTSubscriber's.
// In general, OTSubscriberContext's are generated by the OTStreams component

var OTSubscriberContext = function (_Component) {
  _inherits(OTSubscriberContext, _Component);

  function OTSubscriberContext() {
    _classCallCheck(this, OTSubscriberContext);

    return _possibleConstructorReturn(this, (OTSubscriberContext.__proto__ || Object.getPrototypeOf(OTSubscriberContext)).apply(this, arguments));
  }

  _createClass(OTSubscriberContext, [{
    key: 'getChildContext',
    value: function getChildContext() {
      return { stream: this.props.stream };
    }
  }, {
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'div',
        null,
        this.props.children
      );
    }
  }]);

  return OTSubscriberContext;
}(_react.Component);

exports.default = OTSubscriberContext;


OTSubscriberContext.propTypes = {
  children: _propTypes2.default.element.isRequired,
  stream: _propTypes2.default.shape({
    streamId: _propTypes2.default.string
  }).isRequired
};

OTSubscriberContext.childContextTypes = {
  stream: _propTypes2.default.shape({
    streamId: _propTypes2.default.string
  })
};